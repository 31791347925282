import React from "react";
import {Image} from "../Image";
import {img} from "../../images";

export const Circle = ({x, y}) => {

    return (
        <Image
            src={img.CIRCLE}
            style={{top: y + 'vh', left: x + 'vw'}}
        />
    )
}