import React, {createContext, useState} from "react";


export const ModalContext = createContext()

const emailRegex = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')
const phoneRegex = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$')

export const ModalProvider = ({children}) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [emailCorrect, setEmailCorrect] = useState(true)
    const [phone, setPhone] = useState('')
    const [phoneCorrect, setPhoneCorrect] = useState(true)
    const [visible, setVisible] = useState(false)
    const [callbackOnSubmit, setCallbackOnSubmit] = useState(null)
    const [errorVisible, setErrorVisible] = useState(false)

    const emailHandler = (e) => {
        setEmail(e.target.value)
        setEmailCorrect(true)
        setErrorVisible(false)
    }

    const phoneHandler = (e) => {
        setPhone(e.target.value)
        setPhoneCorrect(true)
        setErrorVisible(false)
    }

    const validateEmail = () => {
        if (emailRegex.test(email)) {
            return true
        }
        setEmailCorrect(false)
        return false
    }

    const validatePhone = () => {
        if (phoneRegex.test(phone)) {
            return true
        }
        setPhoneCorrect(false)
        return false
    }

    const submit = () => {
        const valid = validateEmail() & validatePhone()
        if (valid) {
            callbackOnSubmit(
                {
                    name,
                    email,
                    phone
                },
                () => {
                    setVisible(false)
                }
            )
        } else {
            setErrorVisible(true)
        }
    }

    const showModal = (onSubmitCallback) => {
        setVisible(true)
        setCallbackOnSubmit(() => onSubmitCallback)
    }

    return (
        <ModalContext.Provider value={{showModal}}>
            {children}
            <div className={'modal_window' + (visible ? '' : ' hidden')}>
                <div className="modal">
                    <div className="modal_row">
                        <h5>What's your name?</h5>
                        <input type="text" value={name} onChange={e => setName(e.target.value)}/>
                    </div>
                    <div className="modal_row">
                        <h5>What's your email?</h5>
                        <input
                            type="text"
                            value={email}
                            onChange={e => emailHandler(e)}
                            className={emailCorrect ? '' : 'incorrect'}
                        />
                    </div>
                    <div className="modal_row">
                        <h5>What's your phone number?</h5>
                        <input
                            type="text"
                            value={phone}
                            onChange={e => phoneHandler(e)}
                            className={phoneCorrect ? '' : 'incorrect'}
                        />
                    </div>
                    {errorVisible ? (
                        <h5 style={{color: '#dc3545'}}>Please fix errors above</h5>
                    ) : ''}
                    <button onClick={submit}>Confirm</button>
                    <button className='close_btn' onClick={() => setVisible(false)}>X</button>
                </div>
            </div>
        </ModalContext.Provider>
    )
}