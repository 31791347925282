import React from "react";
import {useHistory} from 'react-router-dom'

export const SubNavBar = ({title, pages, selected, isScrolled}) => {
    const history = useHistory()
    const setClass = (index) => {
        return (selected === index ? 'active ' : ' ') + (index === (pages.length - 1) ? 'buy' : '')
    }

    const handleClick = (index) => {
        history.push(pages[index].link)
    }

    return (
        <div className={'subnavbar'+(isScrolled?' scrolled':'')}>
            <h4>{title}</h4>
            <ul>
                {pages.map((page, index) => (
                    <li key={page.title}
                        onClick={() => handleClick(index)}
                        className={setClass(index)}>
                        {page.title}
                    </li>
                ))}
            </ul>
        </div>
    )
}