export const commands = {
    add_local: 'ADD_GOOD'
}


export const goodsReducer = (state, action) => {
    switch (action.type) {
        case commands.add_local: {
            return state.concat([action.good])
        }
        default: {
            return state
        }
    }
}