import React, {Fragment, useContext, useEffect} from "react";
import {GoodsContext} from "../../../goods/GoodsProvider";
import {BackgroundContext} from "../../../components/background/BackgroundContext";
import {Loader} from "../../../components/Loader";
import {Image} from "../../../components/Image";


export const Overview = ({good}) => {
    const {getOverview, overview, loading} = useContext(GoodsContext)
    const {updateHeight} = useContext(BackgroundContext)

    useEffect(() => {
        async function wtf() {
            await getOverview(good.overview)
            updateHeight()
        }

        wtf()
    }, [])

    return (
        <div id='overview' className='good_subpage'>
            {!overview || loading ? <Loader/> : (
                <Fragment>
                    <div className='overview_page'>
                        <div className="overview_desc_container">
                            <div className='overview_page_desc'>
                                <h1>{good.name}</h1>
                                <h2>{overview.subHeader}</h2>
                            </div>
                        </div>
                        <div className="overview_page_image">
                            <Image src={good.image} alt=""/>
                        </div>
                    </div>
                    {overview.pages.map((page, index) => {
                        let right = (index % 2 === 0)
                        return (
                            <div key={index}
                                 className={'overview_page' + ((index === overview.pages.length - 1) ? ' last' : '')}>
                                {
                                    right ? (
                                        <div className="overview_page_image">
                                            <Image src={page.image} alt=""/>
                                        </div>
                                    ) : ''
                                }
                                <div className={"overview_desc_container" + (right ? ' right' : '')}>
                                    <div className='overview_page_desc'>
                                        <h2>{page.header}</h2>
                                        <h3>{page.subHeader}</h3>
                                        <p>{page.text}</p>
                                    </div>
                                </div>
                                {!right ? (
                                    <div className="overview_page_image">
                                        <Image src={page.image} alt=""/>
                                    </div>
                                ) : ''}
                            </div>
                        )
                    })
                    }
                </Fragment>
            )}
        </div>
    )
}