export const commands = {
    add: 'ADD_GOOD',
    remove: 'REMOVE_GOOD',
    clear: 'CLEAR_ALL',
    changeQuantity: 'CHANGE_QUANTITY',
    total: 'TOTAL_AMOUNT'
}


export const cartGoodReducer = (state, action) => {
    switch (action.type) {
        case commands.add: {
            let changed = false
            const newState = state.map(good => {
                if (good.id === action.good.id) {
                    if (good.options.id === action.good.options.id) {
                        if (good.color.color === action.good.color.color) {
                            const quantity = good.quantity
                            changed = true
                            return {
                                ...good,
                                quantity: quantity + 1
                            }
                        }
                    }
                }
                return good
            })
            if (changed) {
                return newState
            }
            return state.concat([action.good])
        }
        case commands.remove: {
            return state.filter((item, index) => (index !== action.id))
        }
        case commands.changeQuantity: {
            return state.map((good, index) => {
                if (index === action.id) {
                    return {
                        ...good,
                        quantity: action.quantity
                    }
                }
                return good
            })
        }
        case commands.clear: {
            return []
        }
        default: {
            return state
        }
    }
}

export const initCartReducer = () => {
    const localGoods = localStorage.getItem('cartGoods')
    if (localGoods) {
        return (JSON.parse(localGoods))
    }
    return []
}