import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import {BrowserRouter} from 'react-router-dom'
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BackgroundState} from "./components/background/BackgroundContext";
import {NavBarProvider} from "./components/navbar/NavBarContext";
import {CartProvider} from "./components/cart/CartProvider";
import {GoodsProvider} from "./goods/GoodsProvider";
import {Notificator} from "./components/Notificator";
import {ModalProvider} from "./components/modal/ModalContext";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <BackgroundState>
                <Notificator>
                    <ModalProvider>
                        <NavBarProvider>
                            <CartProvider>
                                <GoodsProvider>
                                    <App/>
                                </GoodsProvider>
                            </CartProvider>
                        </NavBarProvider>
                    </ModalProvider>
                </Notificator>
            </BackgroundState>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
