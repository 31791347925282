import React, {useContext, useEffect} from "react";
import {CartContext} from "./CartProvider";
import {useHistory} from 'react-router-dom'
import {Image} from "../Image";
import {ModalContext} from "../modal/ModalContext";
import {NotificatorContext} from "../Notificator";
import {GoodsContext} from "../../goods/GoodsProvider";
import {BackgroundContext} from "../background/BackgroundContext";


export const Cart = () => {
    const {cartGoods, removeCartGood, clearCart, totalAmount, changeQuantity} = useContext(CartContext)
    const {showModal} = useContext(ModalContext)
    const {showNotification} = useContext(NotificatorContext)
    const {submitOrder} = useContext(GoodsContext)
    const {updateHeight} = useContext(BackgroundContext)
    const history = useHistory()

    const quantityChangeHandler = (e, index) => {
        changeQuantity(index, e.target.value * 1)
    }

    const removeHandler = (index) => {
        removeCartGood(index)
    }

    useEffect(() => {
        updateHeight('cart_wrapper')
    }, [cartGoods])

    const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const checkOut = () => {
        console.log(cartGoods)
        //push order object so modal can translate it to server context
        showModal(
            (user, callback) => {
                submitOrder(user, cartGoods, () => {
                    clearCart();
                    showNotification('We\'ve got your order, our specialist will contact you soon')
                    callback()
                    updateHeight()
                })
            }
        )
    }

    return (
        <div id="cart_wrapper" className="cart_wrapper">
            <h2>Cart</h2>
            <div className="cart">
                {(cartGoods && (cartGoods.length > 0)) ?
                    cartGoods.map((good, index) => (
                            <div key={index}
                                 className={"cart_item" + ((index === cartGoods.length - 1) ? ' last' : '')}>
                                <div className="cart_item_image">
                                    <Image src={good.color.image} alt=""/>
                                </div>
                                <div className="cart_item_desc_container">
                                    <div className="desc">
                                        <ul className='desc_main'>
                                            <li>
                                                <h3>{good.title}</h3>
                                            </li>
                                            <li style={{marginBottom: 5}}>
                                                <h5 className="title">Color:</h5>
                                                <h5>{good.color.title}</h5>
                                            </li>
                                            <li>
                                                <p>{good.options.options[0]}</p>
                                            </li>
                                            <li>
                                                <p>{good.options.options[1]}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="quantity">
                                        <select
                                            value={good.quantity}
                                            onChange={e => quantityChangeHandler(e, index)}>
                                            <optgroup>
                                                {quantities.map(item => (
                                                    <option key={item} value={item}>{item}</option>
                                                ))}
                                            </optgroup>
                                        </select>
                                    </div>
                                    <div className="price">
                                        <h3>{good.price}$</h3>
                                        <button onClick={() => removeHandler(index)}>Remove</button>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div>
                            <h3>Cart is empty</h3>
                            <button
                                style={{marginTop: 50}}
                                onClick={() => history.push('/')}>
                                Find your mac
                            </button>
                        </div>
                    )}
            </div>
            {(cartGoods && (cartGoods.length > 0)) ? (
                <div className='total'>
                    <div>
                        <h3 className='title'>Total:</h3>
                        <h3>{totalAmount()}$</h3>
                    </div>
                    <button onClick={checkOut}>Checkout</button>
                </div>
            ) : ''}
        </div>
    )
}