import React, {createContext, useState} from "react";

export const BackgroundContext = createContext()

export const BackgroundState = ({children}) => {
    const [height, setHeight] = useState(null)

    const updateHeight = (id = 'content') => {
        if(document.getElementById(id)) {
            setHeight(document.getElementById(id).scrollHeight)
        }
    }

    return (
        <BackgroundContext.Provider value={{height, updateHeight}}>
            {children}
        </BackgroundContext.Provider>
    )
}