import React, {useEffect, useState} from "react";
import {Image} from "../../components/Image";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination} from 'swiper'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])

export const ImagesSelector = ({images, coloredImage = null, selected, setSelected}) => {

    const [localImages, setLocalImages] = useState([])
    const [swiperSlides, setSwiperSlides] = useState([])
    const [imagesLoaded, setImagesLoaded] = useState(false)
    const [swiper, setSwiper] = useState(null)

    useEffect(() => {
        if(selected===0) {
            if(swiper) {
                swiper.slideTo(selected)
            }
        }
    },[selected])

    useEffect(() => {
        setLocalImages(coloredImage ? [coloredImage].concat(images) : images)
    }, [coloredImage])

    useEffect(() => {
        setSwiperSlides(localImages.map((image, index) => (
            <SwiperSlide
                style={{
                    alignSelf: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                key={`slide-${index}`}
            >
                <Image key={index} src={image} alt={`slide${index}`}/>
            </SwiperSlide>
        )))
    }, [localImages])

    useEffect(() => {
        if (swiperSlides.length > 0) {
            setImagesLoaded(true)
        }
    }, [swiperSlides])


    return (
        <div className='good_image'>
            {imagesLoaded ? (
                <Swiper
                    style={{flex: 1}}
                    observer={{observer: true, observeParents: true}}
                    navigation
                    pagination
                    onSwiper={(swiper) => setSwiper(swiper)}
                    onSlideChange={(swiper) => setSelected(swiper.realIndex)}
                >
                    {swiperSlides}
                </Swiper>
            ) : ''}
        </div>
    )
}