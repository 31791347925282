import React, {useContext, useEffect} from "react";
import {NavBar} from "./navbar/NavBar";
import {Main} from "../pages/mainPage/Main";
import {GoodPage} from "../pages/goodPage/GoodPage";
import {Route, Switch, Redirect} from "react-router-dom";
import {Footer} from "./Footer";
import {Cart} from "./cart/Cart";
import {BackgroundContext} from "./background/BackgroundContext";


export const Content = ({goods, isScrolled, isSafari}) => {
    const {updateHeight} = useContext(BackgroundContext)
    useEffect(updateHeight, [])

    return (
        <div id='content' className="parallax_layer parallax_layer_front">
            <NavBar/>
                <Switch>
                    <Route path="/" exact>
                        <Main/>
                    </Route>
                    {goods.map(good => (
                        <Route key={good.link} path={good.link + '/:page'} exact>
                            <GoodPage good={good} isScrolled={isScrolled}/>
                        </Route>
                    ))}
                    {goods.map(good => (
                        <Redirect key={good.link} from={good.link} to={good.link + '/overview'}/>
                    ))}
                    <Route path="/cart" exact>
                        <Cart/>
                    </Route>
                </Switch>
            <Footer isSafari={isSafari}/>
        </div>
    )
}
