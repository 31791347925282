import React from "react";

export const Loader = () => {

    return (
        <div style={{
            width: '100%',
            height: '100%',
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }} className='main_loader'>
            <h1>Loading...</h1>
        </div>
    )
}