import React, {useContext, useState} from "react";
import {useHistory} from 'react-router-dom'
import {NavBarContext} from "./NavBarContext";
import {CartContext} from "../cart/CartProvider";
import {GoodsContext} from "../../goods/GoodsProvider";
import {Image} from "../Image";
import {img} from "../../images";

export const NavBar = () => {
    const {selectedPage, setSelectedPage} = useContext(NavBarContext)
    const history = useHistory();
    const {goods} = useContext(GoodsContext)
    const items = [{title: 'Products', link: '/'}].concat(goods.map(good => {
        return {
            title: good.name,
            link: good.link
        }
    }))
    const {cartCount} = useContext(CartContext)
    const [burgerVisible, setBurgerVisible] = useState(false)

    const itemClickHandler = (link) => {
        setSelectedPage(link)
        setBurgerVisible(false)
        history.push(link)
    }

    const burgerClickHandler = () => {
        setBurgerVisible(prev => !prev)
    }

    return (
        <header>
            <div className="burger_container">
                <div className='burger_button'>
                    <Image
                        src={img.BURGER}
                        onClick={burgerClickHandler}
                    />
                </div>
                <div className={"burger_nav" + (burgerVisible ? '' : ' hidden')}>
                    <ul>
                        {items.map(item => (
                            <li
                                key={item.link}
                                onClick={() => itemClickHandler(item.link)}
                                className={selectedPage === item.link ? 'selected' : ''}>
                                {item.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="logo_container">
                <Image
                    src={img.LOGO}
                    onClick={() => itemClickHandler('/')}
                />
            </div>
            <nav>
                <ul>
                    {items.map(item => (
                        <li
                            key={item.link}
                            onClick={() => itemClickHandler(item.link)}
                            className={selectedPage === item.link ? 'selected' : ''}>
                            {item.title}
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="nav_cart_wrapper">
                <div className="nav_cart" onClick={() => itemClickHandler('/cart')}>
                    <Image src={img.CART}/>
                    <div className="count">
                        {cartCount !== 0 ? cartCount : ''}
                    </div>
                </div>
            </div>
        </header>
    )
}