export const img = {
    CIRCLE: '/mid-eclipse.svg',
    LOGO: '/logo.png',
    BURGER: '/burger.png',
    LOGO_WHITE: '/logo_white.png',
    CART: '/cart.png',
    ARROW: '/arrow_left.png',
    ICON_INSTAGRAM: '/ig-logo.png',
    ICON_TWITTER: '/tt-logo.png',
    ICON_YOUTUBE: '/yt-logo.png'
}