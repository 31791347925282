import React, {useState} from "react";

const staticPath = '/images'

export const Image = ({src, style = null, className = '', onClick = null, alt = ''}) => {
    const [isLoaded, setIsLoaded] = useState(false)

    const onLoad = () => {
        setIsLoaded(true)
    }

    return (
        <img
            src={staticPath + src}
            onLoad={onLoad}
            className={isLoaded ? className : (className + ' hidden')}
            style={style}
            onClick={onClick}
            alt={alt}
        />
    )
}