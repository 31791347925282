import React, {createContext, useEffect, useState} from "react";
import {useLocation} from 'react-router-dom'

export const NavBarContext = createContext()


export const NavBarProvider = ({children}) => {
    const [selectedPage, setSelectedPage] = useState('/')
    const location = useLocation()

    useEffect(() => {
        const current = location.pathname.split('/')[1]
        if(current) {
            setSelectedPage('/'+current)
        }
    },[])
    useEffect(() => {
        const current = location.pathname.split('/')[1]
        if(current) {
            setSelectedPage('/'+current)
        }
    },[location.pathname])



    return (
        <NavBarContext.Provider value={{selectedPage, setSelectedPage}}>
            {children}
        </NavBarContext.Provider>
    )
}