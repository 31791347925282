import React, {createContext, useEffect, useReducer, useState} from "react";
import {cartGoodReducer, initCartReducer, commands} from "./cartGoodReducer";


export const CartContext = createContext()

export const CartProvider = ({children}) => {
    const [goods, dispatch] = useReducer(
        cartGoodReducer,
        [],
        initCartReducer
    )

    const [needLocalUpdate, setNeedLocalUpdate] = useState(false);

    const addGood = (item) => {
        setNeedLocalUpdate(true)
        dispatch({type: commands.add, good: item})
    }

    const removeGood = (id) => {
        setNeedLocalUpdate(true)
        dispatch({type: commands.remove, id})
    }

    const clearCart = () => {
        setNeedLocalUpdate(true)
        dispatch({type: commands.clear})
    }

    const changeQuantity = (id, quantity) => {
        setNeedLocalUpdate(true)
        dispatch({type: commands.changeQuantity, id, quantity})
    }

    const totalAmount = () => {
        return goods.reduce((result, good) => result + good.price * good.quantity, 0)
    }

    //UPDATE OF LOCAL STORAGE ON GOODS CHANGE AND NEED TRUE
    useEffect(() => {
        if (needLocalUpdate) {
            localStorage.setItem('cartGoods', JSON.stringify(goods))
            setNeedLocalUpdate(false)
        }
    }, [goods])

    return (
        <CartContext.Provider value={{
            cartGoods: goods,
            addCartGood: addGood,
            removeCartGood: removeGood,
            clearCart,
            changeQuantity,
            cartCount: goods.length,
            totalAmount
        }}>
            {children}
        </CartContext.Provider>
    )
}