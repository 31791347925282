import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Background} from "./components/background/Background";
import {useLocation} from 'react-router-dom'
import {Content} from "./components/Content";
import {BackgroundContext} from "./components/background/BackgroundContext";
import {GoodsContext} from "./goods/GoodsProvider";
import {Loader} from "./components/Loader";

function App() {
    const {updateHeight} = useContext(BackgroundContext)
    let location = useLocation()
    const {goods, getAllGoods, mainLoading} = useContext(GoodsContext)
    const [isScrolled, setIsScrolled] = useState(false)
    const [isSafari, setIsSafari] = useState(false)

    useEffect(() => {
        async function wtf() {
            await getAllGoods()
        }
        setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
        wtf()
    }, [])

    useEffect(() => {
        updateHeight()
        const div = document.getElementById('main_container')
        if (div) {
            //safari dirty hack
            if(div.scrollTo) {
                div.scrollTo(0, 0)
            }else {
                div.scrollIntoView()
            }
        }
    }, [location, updateHeight])

    const handleScroll = (e) => {
        if (isScrolled) {
            if (e.target.scrollTop <= 0) {
                setIsScrolled(false)
            }
        }
        if (!isScrolled) {
            if (e.target.scrollTop > 0) {
                setIsScrolled(true)
            }
        }
    }

    return (
        <div
            id='main_container'
            className='parallax_container'
            onScroll={handleScroll}
        >
            {mainLoading ? <Loader/> : (
                <Fragment>
                    <Content goods={goods} isScrolled={isScrolled} isSafari={isSafari}/>
                    {isSafari?'':(
                        <Background/>
                    )}
                </Fragment>
            )}
        </div>
    );
}

export default App
