import React, {Fragment, useContext, useEffect} from "react";
import {GoodsContext} from "../../../goods/GoodsProvider";
import {BackgroundContext} from "../../../components/background/BackgroundContext";
import {Loader} from "../../../components/Loader";


export const Specs = ({good}) => {
    const {getSpecs, specs, loading} = useContext(GoodsContext)
    const {updateHeight} = useContext(BackgroundContext)

    useEffect(() => {
        async function wtf() {
            await getSpecs(good.specs)
            updateHeight()
        }

        wtf()
    }, [])

    const localParse = (input) => {
        const splittedBr = input.split('\n')
        let result = []
        let currentBoldPiece = ''
        let currentPlainPiece = ''
        let writingBold = false
        for (let i = 0; i < splittedBr.length; i++) {
            splittedBr[i].split('').map(char => {
                if (char === '^') {
                    if (writingBold) {
                        writingBold = false
                        result.push({
                            type: 'bold',
                            content: currentBoldPiece
                        })
                        currentBoldPiece = ''
                    } else {
                        writingBold = true
                        result.push({
                            type: 'plain',
                            content: currentPlainPiece
                        })
                        currentPlainPiece = ''
                    }
                } else {
                    if (writingBold) {
                        currentBoldPiece += char
                    } else {
                        currentPlainPiece += char
                    }
                }
            })

            if (writingBold) {
                result.push({
                    type: 'bold',
                    content: currentBoldPiece
                })
                currentBoldPiece = ''
            } else {
                result.push({
                    type: 'plain',
                    content: currentPlainPiece
                })
                currentPlainPiece = ''
            }

            if (i !== splittedBr.length - 1) {
                result.push({
                    type: 'br'
                })
            }
        }

        return result.map(piece => {
            switch (piece.type) {
                case "plain":
                    return piece.content
                case "br":
                    return (<br key={Math.random()}/>)
                case "bold":
                    return (<b key={Math.random()}>{piece.content}</b>)
                default:
                    return ('')
            }
        })
    }


    return (
        <div id='specs' className='good_subpage'>
            <div className="specs_container">
                {!specs || loading ? <Loader/> : (
                    <Fragment>
                        <h2>Specifications</h2>
                        {specs.map((spec, index) => (
                            <div key={index} className={"specs_row" + ((index === specs.length - 1) ? ' last' : '')}>
                                <h4>{spec.title}</h4>
                                <div className='specs_details'>
                                    {spec.options.map((option, index) => (
                                        <p key={index}>
                                            {localParse(option)}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Fragment>
                )}
            </div>


        </div>
    )
}