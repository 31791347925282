import React, {createContext, useContext, useReducer, useState} from "react";
import axios from 'axios'
import {commands, goodsReducer} from "./goodsReducer";
import {NotificatorContext} from "../components/Notificator";


export const GoodsContext = createContext()


export const GoodsProvider = ({children}) => {
    const [goods, dispatch] = useReducer(goodsReducer, [], () => {
        return []
    })
    const [overview, setOverview] = useState(null)
    const [specs, setSpecs] = useState(null)
    const [buy, setBuy] = useState(null)
    //mainLoading should be used only for getAllGoods onse on page
    //enter or refresh
    const [mainLoading, setMainLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const {showNotification} = useContext(NotificatorContext)

    const getAllGoods = async () => {
        setMainLoading(true)
        const res = await axios.get('/api/goods')
        if (res.data) {
            if (res.status === 200) {
                res.data.map(good => {
                    dispatch({type: commands.add_local, good})
                    return good
                })
            }
        }
        setMainLoading(false)
    }

    const getOverview = async (overviewId) => {
        setLoading(true)
        const res = await axios.get(`/api/overview/${overviewId}`)
        if (res.data) {
            if (res.status === 200) {
                setOverview(res.data)
            }
        }
        setLoading(false)
    }

    const getSpecs = async (specsId) => {
        setLoading(true)
        const res = await axios.get(`/api/specs/${specsId}`)
        if (res.data) {
            if (res.status === 200) {
                setSpecs(res.data.pages)
            }
        }
        setLoading(false)
    }

    const getBuy = async (buyId) => {
        setLoading(true)
        const res = await axios.get(`/api/buy/${buyId}`)
        if (res.data) {
            if (res.status === 200) {
                setBuy(res.data.options)
            }
        }
        setLoading(false)
    }

    const submitOrder = async (user, order, callback) => {
        setLoading(true)
        const res = await axios.post('/order/postorder', {
            user,
            order
        })
        if (res.data) {
            console.log('order has been posted')
            callback()
        } else {
            showNotification('Something went wrong, try again')
        }
    }


    return (
        <GoodsContext.Provider value={{
            goods,
            loading,
            mainLoading,
            getAllGoods,
            getOverview,
            overview,
            getSpecs,
            specs,
            getBuy,
            buy,
            submitOrder
        }}>
            {children}
        </GoodsContext.Provider>
    )
}