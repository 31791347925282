import React, {useContext} from "react";
import {MainPageGood} from "./MainPageGood";
import {GoodsContext} from "../../goods/GoodsProvider";


export const Main = () => {
    const {goods} = useContext(GoodsContext)

    return (
        <div>
            <div className='main_goods'>
                {goods.map((good, index) => (
                    <MainPageGood key={index} good={good} direction={index%2===0?'right':'left'}/>
                ))}
            </div>
        </div>
    )
}