import React from "react";
import {useHistory} from 'react-router-dom'
import {Image} from "../../components/Image";


export const MainPageGood = ({good, direction}) => {
    const history = useHistory()

    const learnHandler = (link) => {
        history.push(link)
    }

    const buyHandler = (link) => {
        history.push(link + '/buy')
    }

    return (
        <div className='main_good_container'>
            {direction !== 'right' ? (
                <div className='main_good_image'>
                    <Image src={good.image} alt=''/>
                </div>) : ''
            }
            <div className={'main_good_description' + (direction !== 'right' ? ' right' : '')}>
                <div className='desc_container'>
                    <h2>{good.name}</h2>
                    <ul>
                        {good.description.map(text => (
                            <li key={Math.random()}>
                                {text}
                            </li>
                        ))}
                    </ul>
                    <div className='desc-buttons'>
                        <button onClick={() => buyHandler(good.link)} className='buy'>Buy</button>
                        <button onClick={() => learnHandler(good.link)}>Learn more ></button>
                    </div>
                </div>
            </div>
            {direction === 'right' ? (
                <div className='main_good_image right'>
                    <Image src={good.image} alt='imac'/>
                </div>) : ''
            }
        </div>
    )
}