import React, {useContext, useEffect, useState} from 'react';
import {SubNavBar} from "./SubNavBar";
import {Overview} from "./subpages/Overview";
import {Specs} from "./subpages/Specs";
import {Buy} from "./subpages/Buy";
import {BackgroundContext} from "../../components/background/BackgroundContext";
import {useParams} from 'react-router-dom'


export const GoodPage = ({good, isScrolled}) => {
    const {page} = useParams()

    const pageToIndex = (page) => {
        switch (page) {
            case 'specs':
                return 1
            case 'buy':
                return 2
            default:
                return 0
        }
    }

    const [selected, setSelected] = useState(page ? pageToIndex(page) : 0)
    const {updateHeight} = useContext(BackgroundContext)

    const pages = [
        {
            title: 'Overview',
            link: good.link + '/overview',
            id: 'overview'
        },
        {
            title: 'Specs',
            link: good.link + '/specs',
            id: 'specs'
        },
        {
            title: 'Buy',
            link: good.link + '/buy',
            id: 'buy'
        }
    ]

    useEffect(() => {
        setSelected(pageToIndex(page))
    }, [page])

    useEffect(() => {
        updateHeight(pages[selected].id)
        const div = document.getElementById('main_container')
        //safari hack
        if(div.scrollTo) {
            div.scrollTo(0, 0)
        }else {
            div.scrollIntoView()
        }
    }, [selected])


    return (
        <div>
            <SubNavBar title={good.name} pages={pages} selected={selected} isScrolled={isScrolled}/>

            {selected === 0 ? (
                <Overview good={good}/>
            ) : ''}
            {selected === 1 ? (
                <Specs good={good}/>
            ) : ''}
            {selected === 2 ? (
                <Buy good={good}/>
            ) : ''}
        </div>
    );
}
