import React, {createContext, useState} from "react";


export const NotificatorContext = createContext()


export const Notificator = ({children}) => {
    const [visible, setVisible] = useState(false)
    const [text, setText] = useState('test text')

    let timeout;

    const showNotification = (text) => {
        setText(text)
        if (!visible) {
            setVisible(true)
            timeout = setTimeout(() => {
                setVisible(false)
            }, 2000)
        } else {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                setVisible(false)
            }, 2000)
        }
    }


    return (
        <NotificatorContext.Provider value={{showNotification}}>
            {children}
            <div className={'notificator' + (visible ? '' : ' hidden')}>
                <h5>{text}</h5>
            </div>
        </NotificatorContext.Provider>
    )
}