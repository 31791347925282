import {ImagesSelector} from "../ImagesSelector";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {CartContext} from "../../../components/cart/CartProvider";
import {GoodsContext} from "../../../goods/GoodsProvider";
import {BackgroundContext} from "../../../components/background/BackgroundContext";
import {Loader} from "../../../components/Loader";
import {NotificatorContext} from "../../../components/Notificator";


export const Buy = ({good}) => {
    const [colorSelected, setColorSelected] = useState(0)
    const [selectedPicture, setSelectedPicture] = useState(null)
    const [selectedModel, setSelectedModel] = useState(0)

    const {getBuy, buy, loading} = useContext(GoodsContext)
    const {updateHeight} = useContext(BackgroundContext)
    const {showNotification} = useContext(NotificatorContext)

    useEffect(() => {
        async function wtf() {
            await getBuy(good.buy)
            updateHeight()
        }

        wtf()
    }, [])

    const {addCartGood} = useContext(CartContext)

    const addHandler = () => {
        addCartGood({
            title: good.name,
            color: good.colors.length !== 0 ? {
                title: good.colors[colorSelected].title,
                image: good.colors[colorSelected].image,
                color: good.colors[colorSelected].color
            } : {
                title: 'The Best One',
                image: good.image,
                color: '#ffffff'
            },
            options: {
                title: buy[selectedModel].title,
                amount: buy[selectedModel].amount,
                options: buy[selectedModel].options,
                id: buy[selectedModel]._id
            },
            price: buy[selectedModel].amount,
            quantity: 1,
            id: good._id
        })
        showNotification(good.name + ' was added to cart')
    }

    return (
        <div id='buy' className='good_container good_subpage'>
            {!buy || loading ? <Loader/> : (
                <Fragment>
                    <ImagesSelector
                        images={good.images}
                        selected={selectedPicture}
                        setSelected={setSelectedPicture}
                        coloredImage={good.colors.length > 0 ? good.colors[colorSelected].image : null}/>

                    <div className="good_choice_wrapper">
                        <div className="good_choice">
                            <h3>{good.name}</h3>

                            {good.colors.length !== 0 ? (
                                <div className="color_selector_wrapper">
                                    <h4>Pick a color:</h4>
                                    <div className="color_selector">
                                        {good.colors.map((color, index) => (
                                            <div
                                                key={index}
                                                style={{background: color.color}}
                                                className={index === colorSelected ? 'selected' : ''}
                                                onClick={() => {
                                                    setColorSelected(index)
                                                    setSelectedPicture(0)
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : ''}


                            <div className='model_selector'>
                                <div className="models_wrapper">
                                    <h4>Choose your mac:</h4>
                                    <div className='models'>
                                        {buy.map((option, index) => (
                                            <div
                                                key={index}
                                                className={selectedModel === index ? 'selected' : ''}
                                                onClick={() => setSelectedModel(index)}>
                                                {option.title}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <ul>
                                    {buy[selectedModel].options.map((option, index) => (
                                        <li key={index}>
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="price_wrapper">
                                <h4 className='price'>Price: {buy[selectedModel].amount}$</h4>
                                <button onClick={addHandler}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    )
}