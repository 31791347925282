import React, {Fragment, useContext} from "react";
import {Circle} from "./Circle";
import {BackgroundContext} from "./BackgroundContext";

export const Background = () => {
    const {height} = useContext(BackgroundContext)

    const sizes = [16, 24, 36]

    const circles = [
        {x: 75, y: -20, size: 2},
        {x: -5, y: 120, size: 2},
        {x: 20, y: 200, size: 2},
        {x: -15, y: 25, size: 1},
        {x: 0, y: 115, size: 1},
        {x: 73, y: 145, size: 1},
        {x: 75, y: 250, size: 1},
        {x: 40, y: 60, size: 0},
        {x: 70, y: 110, size: 0},
        {x: 50, y: 190, size: 0},
        {x: 35, y: 280, size: 0},
    ]

    const getDifference = () => {
        const container = document.getElementById('main_container')
        const content = document.getElementById('content')
        if (container && content) {
            return container.scrollHeight - content.scrollHeight
        }
        return null
    }

    const getCircles = (max) => {
        const vh = window.innerHeight / 100;
        const vw = window.innerWidth / 100;
        return circles.filter(circle => {
            //console.log(`circle with y:${circle.y} and size: ${sizes[circle.size]} or y:${Math.round(circle.y * vh)} size:${Math.round(sizes[circle.size] * vw)} || ${(circle.y * vh + sizes[circle.size] * vw) < max}`)
            return (circle.y * vh + sizes[circle.size] * vw) < max
        })
    }

    return (
        <Fragment>
            <div className="parallax_layer parallax_layer_small">
                {height ? getCircles(height).map((circle, index) => {
                    if (circle.size === 0) {
                        return (
                            <Circle
                                key={index}
                                x={circle.x}
                                y={circle.y}
                                size={circle.size}
                            />
                        )
                    }
                    return ''
                }) : ''}
            </div>
            <div className="parallax_layer parallax_layer_medium">
                {height ? getCircles(height).map((circle, index) => {
                    if (circle.size === 1) {
                        return (
                            <Circle
                                key={index}
                                x={circle.x}
                                y={circle.y}
                                size={circle.size}
                            />
                        )
                    }
                    return ''
                }) : ''}
            </div>
            <div className="parallax_layer parallax_layer_big">
                {height ? getCircles(height).map((circle, index) => {
                    if (circle.size === 2) {
                        return (
                            <Circle
                                key={index}
                                x={circle.x}
                                y={circle.y}
                                size={circle.size}
                            />
                        )
                    }
                    return ''
                }) : ''}
            </div>
        </Fragment>
    )
}