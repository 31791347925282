import React from "react";
import {Image} from "./Image";
import {img} from "../images";

export const Footer = ({isSafari}) => {


    return (
        <footer className={isSafari?'safari':''}>
            <div>
                <Image src={img.LOGO_WHITE} alt="logo"/>
                <h4>Apple</h4>
            </div>
            <ul>
                <a href='http://instagram.com'>
                    <li><Image src={img.ICON_INSTAGRAM} alt="instagram"/></li>
                </a>
                <a href='http://twitter.com'>
                    <li><Image src={img.ICON_TWITTER} alt="twitter"/></li>
                </a>
                <a href='http://youtube.com'>
                    <li><Image src={img.ICON_YOUTUBE} alt="youtube"/></li>
                </a>
            </ul>
        </footer>
    )
}